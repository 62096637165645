<template>

    <div class="phi-person-set-condition-employee-groups">
        <div class="field year">
            <label>{{ $t("noun.academicYear") }}</label>
            <select v-model="model.year" v-if="availableYears.length">
                <option v-for="year in availableYears" :value="year.id">{{ year.name }}</option>
            </select>
        </div>

        <div class="field status">
            <label>{{ $t("noun.status") }}</label>
            <select v-model="model.status" multiple="multiple">
                <option value="0">{{ $t("adj.statusActive") }}</option>
                <option value="1">{{ $t("adj.statusInactive") }}</option>
            </select>
        </div>

        <div class="field group">
            <label>{{ $t("noun.employee-group") }}</label>
            <select multiple v-model="model.group">
                <option v-for="egroup in egroups" :value="egroup.id">{{ egroup.name }}</option>
            </select>
        </div>
    </div>

</template>

<script>
export default {
    name: "phi-person-set-condition-employee-groups",

    props: {
        value: {},
        api: {
            required: true
        }
    },

    data() {
        return {
            model: {
                group: [],
                year: null,
                status: []
            },
            availableYears: [],
            egroups: []
        }
    },

    methods: {
        loadEGroups() {
            this.api
                .get("/v3/person/employee/group")
                .then(response => this.egroups = response);
        }
    },

    watch: {
        "model": {
            deep: true,
            handler(newValue) {
                this.$emit("input", this.model);
            }
        }
    },

    mounted() {
        this.loadEGroups();
    },
    beforeMount() {
        this.api
            .get("/v3/years")
            .then(years => {
                this.availableYears = years;

                /* Set initial value */
                if (this.value) {
                    this.model.year    = typeof this.value.year    != 'undefined' ? this.value.year    : null;
                    this.model.status  = typeof this.value.status  != 'undefined' ? this.value.status  : null;
                    this.model.group = typeof this.value.group != 'undefined' ? this.value.group : null;
                }

                if (!this.model.year) {
                    for (var i = 0; i < this.availableYears.length; i++) {
                        if (this.availableYears[i].current == "1") {
                            this.model.year = this.availableYears[i].id;
                            break;
                        }
                    }
                    if (!this.model.year) {
                        this.model.year = this.availableYears[0].id
                    }
                }
            });
    }
}
</script>

<style lang="scss">
.phi-person-set-condition-employee-groups {
    display: flex;
    flex-wrap: wrap;

    .field {
        display: block;
        margin: 0 6px 12px 6px;

        label {
            display: block;
            margin-bottom: 2px;
            font-size: 12px;
            font-weight: bold;
        }

        &.status {
            select {
                height: 8em;
            }
        }

        &.group {
            select {
                min-height: 12em;
            }
        }
    }
}
</style>
