<template>

    <div class="phi-person-set-condition-people-set-memberships">
        <div class="field year">
            <label>{{ $t("noun.academicYear") }}</label>
            <select v-model="model.year" v-if="availableYears.length">
                <option v-for="year in availableYears" :value="year.id">{{ year.name }}</option>
            </select>
        </div>

        <div class="field group">
            <label>{{ $t("noun.groups") }}</label>
            <select v-model="model.pset" multiple="multiple">

                <option v-for="group in availablePeopleSets" :value="group.id">{{ group.name }}</option>

            </select>
        </div>
    </div>

</template>

<script>
export default
{
    name: "phi-person-set-condition-people-set-memberships",

    props: {
        value: {},
        api: {
            required: true
        }
    },

    data() {
        return {
            model: {
                year: null,
                pset: []
            },
            availableYears: [],
            peopleSets: {}
        }
    },

    methods: {
        fetchSets(yearId) {
            if (typeof this.peopleSets[yearId] == "undefined") {
                this.api
                    .get("/v3/people/set", {year: yearId})
                    .then(groups => this.$set(this.peopleSets, yearId, groups));
            }
        }
    },

    computed: {
        availablePeopleSets() {
            if (typeof this.peopleSets[this.model.year] == "undefined") {

                return false;
            }

            var groups = [];

            for (var i = 0; i < this.peopleSets[this.model.year].length; i++)
            {
                var curGroups = this.peopleSets[this.model.year][i];
                groups.push(curGroups);
            }

            return groups;
        }
    },

    watch: {

        "model": {
            deep: true,
            handler(newValue) {
                this.$emit("input", this.model);
            }
        },

        "model.year": function(yearId, oldValue) {
            if (oldValue != null) {
                this.model.pset = [];
            }
            this.fetchSets(yearId);
        }
    },

    beforeMount() {
        this.api
        .get("/v3/years")
        .then(years => {
            this.availableYears = years;

            /* Set initial value */
            if (this.value) {
                this.model.year    = typeof this.value.year    != 'undefined' ? this.value.year    : null;
                this.model.pset  = typeof this.value.pset  != 'undefined' ? this.value.pset  : null;
            }

            if (!this.model.year) {
                for (var i = 0; i < this.availableYears.length; i++)
                {
                    if (this.availableYears[i].current == "1") {
                        this.model.year = this.availableYears[i].id;
                        break;
                    }
                }
                if (!this.model.year) {
                    this.model.year = this.availableYears[0].id
                }
            }
        });
    }

}
</script>

<style lang="scss">
    .phi-person-set-condition-people-set-memberships
    {
        display: flex;
        flex-wrap: wrap;

        .field {
            display: block;
            margin: 0 6px 12px 6px;

            label {
                display: block;
                margin-bottom: 2px;
                font-size: 12px;
                font-weight: bold;
            }

            &.group {
                select {
                    min-height: 12em;
                }
            }
        }
    }
</style>
