<template>
    <select :multiple="options.multiple" @change="change" v-model="selected">
        <option v-for="(label, value) in options.options" :value="value">{{ label }}</option>
    </select>
</template>

<script>
export default {
    name: "phi-person-set-condition-select",
    props: {
        value: {},
        options: {}
    },

    data() {
        return {
            selected: this.value
        }
    },

    methods: {
        change(e) {
            this.$emit("input", this.selected);
        }
    }
}
</script>