<template>
    <div class="phi-post-type-rule">
        <section class="audience">
            <phi-person-set :api="api" v-model="value.audience" :show-total="true"></phi-person-set>
        </section>
        <section class="permissions">
            <section>
                <mu-checkbox :label="$t('action.redact')" :value="redact.to || redact.bcc" @change="toggleRedact" />
                <div style="padding-left: 12px">
                    <mu-checkbox :label="$t('noun.to')" v-model="redact.to" />
                    <br>
                    <mu-checkbox :label="$t('noun.bcc')" v-model="redact.bcc" />
                </div>
            </section>

            <section>
                <mu-checkbox :label="$t('action.attach')" :value="!!value.settings.attach.length" @change="toggleAttachmentAll" />
                <div style="padding-left: 12px">
                    <template v-for="block in blocks">
                        <mu-checkbox :label="block.name" :value="hasAttachmentBlock(block.type)" @change="toggleAttachmentBlock(block.type)" />
                        <br>
                    </template>
                </div>
            </section>

            <section>
                <mu-checkbox :label="$t('action.addToCalendar')" v-model="value.settings.calendar" />
                <mu-checkbox :label="$t('action.schedulePublishDate')" v-model="value.settings.schedulePublishDate" />
                <mu-checkbox :label="$t('action.scheduleExpirationDate')" v-model="value.settings.scheduleExpirationDate" />
                <mu-checkbox :label="$t('Activar el responder a')" v-model="value.settings.replyTarget" />
            </section>
        </section>

        <section class="settings">

            <table>
                <thead>
                    <tr>
                        <td></td>
                        <td>{{ $t('noun.defaultValue') }}</td>
                        <td>{{ $t('noun.editable') }}</td>
                    </tr>
                </thead>

                <tbody>
                    <!-- Ocultar destinatarios -->
                    <tr>
                        <td>{{ $t('action.HideRecipients') }}</td>
                        <td>
                            <select v-model="value.settings.bcc.default">
                                <option :value="true">{{ $t('yes') }}</option>
                                <option :value="false">{{ $t('no') }}</option>
                            </select>
                        </td>
                        <td>
                            <mu-checkbox v-model="value.settings.bcc.editable" />
                        </td>
                    </tr>

                    <!-- Permitir respuestas -->
                    <tr>
                        <td>{{ $t('action.allowReplies') }}</td>
                        <td>
                            <select v-model="value.settings.allowReplies.default">
                                <option :value="true">{{ $t('yes') }}</option>
                                <option :value="false">{{ $t('no') }}</option>
                            </select>
                        </td>
                        <td>
                            <mu-checkbox v-model="value.settings.allowReplies.editable" />
                        </td>
                    </tr>
                    <!-- Permitir respuestas a Todos-->
                    <tr>
                        <td>{{ $t('action.allowRepliesAll') }}</td>
                        <td>
                            <select v-model="value.settings.allowRepliesAll.default">
                                <option :value="true">{{ $t('yes') }}</option>
                                <option :value="false">{{ $t('no') }}</option>
                            </select>
                        </td>
                        <td>
                            <mu-checkbox v-model="value.settings.allowRepliesAll.editable" />
                        </td>
                    </tr>
                   <!-- Permitir Reenviar-->
                    <tr>
                        <td>{{ $t('action.allowForward') }}</td>
                        <td>
                            <select v-model="value.settings.allowForward.default">
                                <option :value="true">{{ $t('yes') }}</option>
                                <option :value="false">{{ $t('no') }}</option>
                            </select>
                        </td>
                        <td>
                            <mu-checkbox v-model="value.settings.allowForward.editable" />
                        </td>
                    </tr>
                    <!-- Marcar en cartelera -->
                    <tr>
                        <td>{{ $t('action.highlightPost') }}</td>
                        <td>
                            <select v-model="value.settings.highlight.default">
                                <option :value="true">{{ $t('yes') }}</option>
                                <option :value="false">{{ $t('no') }}</option>
                            </select>
                        </td>
                        <td>
                            <mu-checkbox v-model="value.settings.highlight.editable" />
                        </td>
                    </tr>

                    <!-- Permitir notificaciones -->
                    <tr>
                        <td>{{ $t('action.notify') }}</td>
                        <td>
                            <select v-model="value.settings.notify.default">
                                <option :value="true">{{ $t('yes') }}</option>
                                <option :value="false">{{ $t('no') }}</option>
                            </select>
                        </td>
                        <td>
                            <mu-checkbox v-model="value.settings.notify.editable" />
                        </td>
                    </tr>
                </tbody>

            </table>

        </section>
    </div>
</template>

<script>
import PhiPersonSet from '../../Person/Set.vue';

export default {
    components: {
        PhiPersonSet
    },

    props: {
        value: {
            type: Object,
            required: true
        },

        api: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            blocks: [
                {
                    "type": "youtube",
                    "name": "Youtube"
                },
                {
                    "type": "html",
                    "name": "HTML"
                },
                {
                    "type": "files",
                    "name": "Archivos"
                },
                {
                    "type": "form",
                    "name": "Formulario"
                },
                {
                    "type": "googleDocument",
                    "name": "Google Drive"
                }
            ],

            redact: {
                to: false,
                bcc: false
            }
        }
    },

    beforeMount() {
        this.redact.to  = this.value.settings.redact & 1;
        this.redact.bcc = this.value.settings.redact & 2;

        if (typeof this.value.settings.allowReplies == 'undefined') {
            this.value.settings.allowReplies = {
                default: true,
                editable: false
            };
        }

        if (typeof this.value.settings.allowRepliesAll == 'undefined') {
            this.value.settings.allowRepliesAll = {
                default: true,
                editable: false
            };
        }

        if (typeof this.value.settings.allowForward == 'undefined') {
            this.value.settings.allowForward = {
                default: true,
                editable: false
            };
        }





    },

    methods: {
        toggleRedact() {
            var val = this.redact.to || this.redact.bcc;
            this.redact.to  = !val;
            this.redact.bcc = !val;
        },

        toggleAttachmentAll() {
            this.value.settings.attach.length ? this.value.settings.attach = [] : this.value.settings.attach = this.blocks.map(block => block.type);
        },

        toggleAttachmentBlock(type) {
            var pos = this.value.settings.attach.indexOf(type);
            pos >= 0 ? this.value.settings.attach.splice(pos, 1) : this.value.settings.attach.push(type);
        },

        hasAttachmentBlock(type) {
            return this.value.settings.attach.indexOf(type) >= 0;
        }
    },

    watch: {
        value: {
            deep: true,
            handler(newValue) {
                this.$emit("input", newValue);
            }
        },

        redact: {
            deep: true,
            handler() {
                this.value.settings.redact = (this.redact.to && 1) | (this.redact.bcc && 2);
            }
        }
    }
}
</script>

<style lang="scss">
.phi-post-type-rule {
    & > section {
        padding: 8px 16px;

        &.audience {
            border-bottom: 1px solid #f2f2f2;
        }
    }

    .mu-checkbox-icon-checked {
        color: rgb(28, 137, 184);
    }

    .mu-checkbox input[type="checkbox"]:checked + .mu-checkbox-wrapper .mu-checkbox-icon-uncheck {
        color: #ccc;
    }

    .permissions {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;

        section {
            flex: 1;
            min-width: 180px;
            padding-bottom: 16px;
        }
    }

    .settings {
        table {
            width: 100%;

            thead {
                font-size: 12px;
                color: #000;
            }
        }
    }
}
</style>