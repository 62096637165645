<template>
    <div class="phi-person-set-condition-class">
        <div class="field year">
            <label>{{ $t("noun.academicYear") }}</label>
            <select v-model="currentYear" @change="triggerCurrentYearChange(true)">
                <option v-for="year in years" :value="year">{{ year.name }}</option>
            </select>
        </div>
    
        <div class="field period">
            <label>{{ $t("noun.periods") }}</label>
            <select multiple v-model="model.periods" v-if="!!currentYear">
                <optgroup v-for="periodicity in currentYear.periodicities" :label="periodicity.name">
                    <option v-for="period in periodicity.periods" :value="period.id">{{ period.name }}</option>
                </optgroup>
            </select>
        </div>
    
        <div class="field academic-group">
            <label>{{ $t("noun.academicGroups") }}</label>
    
            <div class="toolbar phi-media" v-if="fuse !== null && areas.length > 0">
                <div class="phi-media-body">
                    <input class="search-field" type="text" v-model="query" :placeholder="$t('search')" />
                </div>
                <button type="button" class="phi-media-right phi-button" v-if="query.length > 0" @click="query = ''">
                    <i class="fa fa-times"></i>
                </button>
                <button type="button" class="phi-media-right phi-button" v-else>
                    <i class="fa fa-search"></i>
                </button>
            </div>
    
            <section class="areas">
                <div class="area" v-for="area in filteredAreas">
                    <div v-bind:class="{ match: (area.name.toLowerCase().includes(query.toLowerCase()) && query.length> 0)}">
                        <input type="checkbox" :id="'area_'+area.id" @change="toggleArea(area)" :checked="isAreaSelected(area)" />
                        <label :for="'area_'+area.id">{{ area.name }}</label>
                    </div>
                    <section class="subjects">
                        <div class="subject" v-for="subject in area.subjects">
                            <div v-bind:class="{ match: (subject.name.toLowerCase().includes(query.toLowerCase()) && query.length> 0)}">
                                <input type="checkbox" :id="'subject_'+subject.id" @change="toggleSubject(subject)" :checked="isSubjectSelected(subject)" />
                                <label :for="'subject_'+subject.id">{{ subject.name }}</label>
                            </div>
                            <section class="courses">
                                <div class="course" v-for="course in subject.courses">
                                    <div v-bind:class="{ match: (course.name.toLowerCase().includes(query.toLowerCase())&& query.length> 0)}">
                                        <input type="checkbox" :id="'course_'+course.id" @change="toggleCourse(course)" :checked="isCourseSelected(course)" />
                                        <label :for="'course_'+course.id">{{ course.name }}</label>
                                    </div>
                                    <section class="groups">
                                        <div class="group" v-for="group in course.groups">
                                            <div v-bind:class="{ match: (group.name.toLowerCase().includes(query.toLowerCase())&& query.length> 0)}">
                                                <input type="checkbox" :id="'group_'+group.id" :checked="isGroupSelected(group)" @change="toggleGroup(group)" />
                                                <label :for="'group_'+group.id">{{ group.name }}</label>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </section>
                        </div>
                    </section>
                </div>
            </section>
        </div>

        <div class="field grades">
             <select v-model="model.grade.countOperator">
                <option value="1">{{ $t("less than") }}</option>
                <option value="2">{{ $t("less than or exactly") }}</option>
                <option value="3">{{ $t("exactly") }}</option>
                <option value="4">{{ $t("more than or exactly") }}</option>
                <option value="5">{{ $t("more than") }}</option>
            </select>   

            <input type="number" min="1" max="100" step="1" v-model="model.grade.count" >

            <select v-model="model.grade.type">
                <option value="0">{{ $t("noun.none") }}</option>
                <option value="1">{{ $t("grades") }}</option>
                <option value="2">{{ $t("cumulatives") }}</option>
            </select>   

            <select v-model="model.grade.operator">
                <option value="1">{{ $t("graded less than") }}</option>
                <option value="2">{{ $t("graded less than or equal to") }}</option>
                <option value="3">{{ $t("graded equal to") }}</option>
                <option value="4">{{ $t("graded greater than or equal to") }}</option>
                <option value="5">{{ $t("graded greater than") }}</option>
            </select>  

            <input type="text" maxlength="6" v-model="model.grade.value"/>  
        </div>
    </div>
</template>

<script>
import Fuse from 'fuse.js';

export default {
    name: 'phi-person-set-condition-class',
    props: ['api', 'value'],
    data() {
        return {
            years: [],
            currentYear: null,
            areas: [], // areas del año actual (esto cambia cuando cambia currentYear)
            model: this.value,
            loadingModel: false,

            query: '',
            fuse: null,
            searchOptions: {
                shouldSort: true,
                threshold: 0.2,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 2,
                keys: [
                    "name",
                    "subjects.name",
                    "subjects.courses.name",
                    "subjects.courses.groups.name"
                ]
            }
        }
    },

    computed: {
        filteredAreas() {
            let query = this.query.trim();

            let finalArray = [];

            if (query.length > 0) {
                finalArray = this.fuse.search(query);
            } else {
                finalArray = this.areas;
            }
            
            return finalArray;
        }
    },

    methods: {
        loadYears() {
            return this.api
                .get(`/v3/academic/periods`)
                .then(response => this.years = response);
        },

        triggerCurrentYearChange(doReset) {
            if (doReset && this.loadingModel === false) {
                this.model.groups = [];
                this.model.periods = this.getPeriodIds(this.currentYear);
            }

            this.fuse = null;

            this.api
                .get(`/v3/years/${this.currentYear.id}/academic/course/groups`)
                .then(response => {
                    this.areas = response;
                    
                    this.fuse = new Fuse(this.areas, this.searchOptions);

                    this.loadingModel = false;
                })
        },

        getPeriodIds(year) {
            let retval = [];
            year.periodicities.forEach(periodicity => {
                periodicity.periods.forEach(period => retval.push(period.id));
            });
            return retval;
        },

        findYearWithPeriod(periodId) {
            for (let y = 0; y < this.years.length; y++) {
                let year = this.years[y];

                for (let p = 0; p < year.periodicities.length; p++) {
                    let periodicity = year.periodicities[p];

                    for (let i = 0; i < periodicity.periods.length; i++) {
                        if (periodicity.periods[i].id == periodId) {
                            return year;
                        }
                    }
                }
            }

            return null;
        },

        isGroupSelected(group) {
            return this.model.groups.indexOf(group.id) >= 0;
        },

        selectGroup(group) {
            if (this.isGroupSelected(group)) {
                return;
            }
            this.model.groups.push(group.id);
        },

        deselectGroup(group) {
            this.model.groups.splice(this.model.groups.indexOf(group.id), 1);
        },

        toggleGroup(group) {
            this.isGroupSelected(group) ? this.deselectGroup(group) : this.selectGroup(group);
        },

        isCourseSelected(course) {
            return course.groups.some(this.isGroupSelected);
        },

        selectCourse(course) {
            course.groups.forEach(this.selectGroup);
        },

        deselectCourse(course) {
            course.groups.forEach(this.deselectGroup);
        },

        toggleCourse(course) {
            this.isCourseSelected(course) ? this.deselectCourse(course) : this.selectCourse(course);
        },


        isSubjectSelected(subject) {
            return subject.courses.some(this.isCourseSelected);
        },

        selectSubject(subject) {
            subject.courses.forEach(this.selectCourse);
        },

        deselectSubject(subject) {
            subject.courses.forEach(this.deselectCourse);
        },

        toggleSubject(subject) {
            this.isSubjectSelected(subject) ? this.deselectSubject(subject) : this.selectSubject(subject);
        },

        isAreaSelected(area) {
            return area.subjects.some(this.isSubjectSelected);
        },

        selectArea(area) {
            area.subjects.forEach(this.selectSubject);
        },

        deselectArea(area) {
            area.subjects.forEach(this.deselectSubject);
        },

        toggleArea(area) {
            this.isAreaSelected(area) ? this.deselectArea(area) : this.selectArea(area);
        }
    },

    mounted() {
        this.loadYears()
            .then(() => {
                if (this.model.periods.length) {
                    this.loadingModel = true;
                    this.currentYear = this.findYearWithPeriod(this.model.periods[0]);
                } else {
                    for (var i = 0; i < this.years.length; i++) {
                        if (this.years[i].current == "1") {
                            this.currentYear = this.years[i];
                            break;
                        }
                    }

                    if (this.currentYear === null) {
                        this.currentYear = this.years[0];
                    }
                }

                this.triggerCurrentYearChange(false);
            });
    },

    watch: {
        model: {
            deep: true,
            handler() {
                this.$emit('input', this.model);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
section {
    &>div {
        &>h2 {
            font-size: 1em;
        }
    }

    section {
        padding-left: 16px;
    }
}

.phi-person-set-condition-class {
    display: flex;
    flex-wrap: wrap;

    .field {
        display: block;
        margin: 0 6px 12px 6px;

        >label {
            display: block;
            margin-bottom: 2px;
            font-size: 12px;
            font-weight: bold;
        }

        &.status {
            select {
                height: 8em;
            }
        }

        &.section {
            select {
                min-height: 18em;
            }
        }
    }
}

input.search-field{
    width: 100%;
    content: '\1F50D';
    background: transparent;
    font-size: 1em;
    border: 0;
    border-bottom: 1px solid #999;
}

.toolbar{
    box-sizing: border-box;
    height: 40px;
    padding: 0 16px 10px 16px;
    display: flex;
    align-items: center;

    button{
        background: transparent;
        border: 0;
        padding: 0;
        font-size: 1.2em;
        width: 32px;
        height: 32px;
        color: inherit;
        box-shadow: none;
    }
}

.match{
    color: black;
    background-color: #E3E846;
    font-weight: bolder;
}
</style>