<template>
    <div class="phi-person-set-condition-roles">
        <select multiple v-model="selectedRoles" @change="$emit('input', selectedRoles)">
            <option v-for="role in roles" :value="role.id">{{ role.name }}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: "phi-person-set-condition-roles",
    props: ["api", "value"],
    data() {
        return {
            roles: [],
            selectedRoles: this.value
        }
    },

    methods: {
        loadRoles() {
            this.api
                .get("/v3/people/groups")
                .then(response => this.roles = response);
        }
    },

    mounted() {
        this.loadRoles();
    }
}
</script>