<template>
	<div>

		<select v-model="currentYear" @change="newValueCurrentYear(currentYear)">
            <option v-for="year in years" :value="year">{{year.name}}</option>
        </select>

        <select multiple v-model="model.periods" v-if="!!currentYear">
            <optgroup v-for="periodicity in currentYear.periodicities" :label="periodicity.name">
                <option v-for="period in periodicity.periods" :value="period.id">{{ period.name }}</option>
            </optgroup>
        </select>

        <select multiple v-model="model.mentions" v-if="!!currentYear">
            <optgroup :label="$t('noun.Mentions')">
                <option v-for="mention in mentions" :value="mention.id">{{ mention.name }}</option>
            </optgroup>
        </select>

	</div>
</template>

<script>
export default {
	name: "phi-person-set-condition-mentions",
    props: ["api", "value"],

    data() {
    	return {
    		years: [],
            currentYear: null,
            model: this.value,
            mentions: []
    	}
    },

    methods: {
    	loadYears() {
            return this.api
                .get(`/v3/academic/periods`)
                .then(response => this.years = response);
        },

        loadMentions(year) {
            return this.api
                .get(`/v3/mention/${year}`)
                .then(response => this.mentions = response);
        },

        newValueCurrentYear(year) {
            this.model.year = year.id;
            this.loadMentions(year.id);
        }
    },

    mounted() {
        this.loadYears()
            .then(() => {
                for (var i = 0; i < this.years.length; i++) {
                    if (this.years[i].current == "1") {
                        this.currentYear = this.years[i];
                        this.loadMentions(this.currentYear.id);
                        this.model.year = this.currentYear.id;
                        break;
                    }
                }
            });
    }
}
</script>

<style lang="scss" scoped>

</style>