<template>
    <div class="phi-person-set-condition-debit-price">
        <div class="descriptor-row phi-card" v-for="descriptor in descriptors">
            
            <debit-price-descriptor :api="api" v-model="descriptor.description"></debit-price-descriptor>

            <div class="descriptor-toolbar"> 
                <i class="mu-icon material-icons" @click="removeDescriptor(descriptor.id)">close</i>  
            </div>

        </div>

        <div class="condition-toolbar">
            <i class="mu-icon material-icons" @click="addDescriptor()">add</i>
        </div>
    </div>
</template>

<script>
import DebitPriceDescriptor from './DebitPriceDescriptor.vue';

export default {
    name: 'phi-person-set-condition-debit-price',
    components: {
        DebitPriceDescriptor
    },
    props: ['api', 'value'],
    data() {
        return {
            model: this.value,
            descriptors: [],
            descriptionIndex: 0
        }
    },

    methods: {
        addDescriptor(description) {
            if(description){
                this.descriptors.push({
                    id: this.descriptionIndex += 1,
                    description: description
                });
            }else{
                this.descriptors.push({
                    id: this.descriptionIndex += 1,
                    description: {
                        periods: [],
                        prices: [],
                        operator: ">",
                        balance: 0
                    }
                });
            }
        },

        removeDescriptor(descriptorId) {
            this.descriptors = this.descriptors.filter(elemDescription => elemDescription.id != descriptorId);
        }

    },

    mounted() {
        if(this.model.length > 0){
            this.model.forEach(descriptor => this.addDescriptor(descriptor));
        }else{
            this.addDescriptor(this.descriptionTemplate)
        }
    },

    watch: {
        model: {
            deep: true,
            handler() {
                this.$emit('input', this.model);
            }
        },

        descriptors: {
            deep: true,
            handler() {
                this.model = [];
                this.descriptors.forEach(description => {this.model.push(description.description)});
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.phi-person-set-condition-debit-price{
    .descriptor-row{
        padding: 10px;
        margin-bottom: 10px;

        .descriptor-toolbar{
            text-align: right;

            i{
                cursor: pointer;
            }
        }    
    }

    .condition-toolbar{
        text-align: center;

        i{
            cursor: pointer;
        }    
    }
}
</style>